// import axios from "axios";

// const baseURL = "http://127.0.0.1:8000/api/";

// const axiosInstance = axios.create({
//   baseURL: baseURL,
//   timeout: 5000,
//   headers: {
//     "Content-Type": "application/json",
//     Authorization: localStorage.getItem("access_token")
//       ? "JWT " + localStorage.getItem("access_token")
//       : null,
//     accept: "application/json",
//   },
//   withCredentials: true,
// });

// axiosInstance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async function (error) {
//     const originalRequest = error.config;

//     if (typeof error.response === "undefined") {
//       alert(
//         "A server or network error occured. " +
//           "Look like CORS might be problem " +
//           "Sorry about this - we will get it fixed shortly."
//       );
//       return Promise.reject(error);
//     }

//     if (
//       error.response.status === 401 &&
//       originalRequest.url === baseURL + "token/refresh/"
//     ) {
//       window.location.href = "/login/";
//       return Promise.reject(error);
//     }

//     if (
//       error.response.data.code === "token_not_valid" &&
//       error.response.status === 401 &&
//       error.response.statusText === "Unauthorized"
//     ) {
//       const refreshToken = localStorage.getItem("refresh_token");

//       if (refreshToken) {
//         const tokenParts = JSON.parse(atob(refreshToken.split(".")[1]));

//         const now = Math.ceil(Date.now() / 1000);
//         console.log(tokenParts.exp);

//         if (tokenParts.exp > now) {
//           return axiosInstance
//             .post("/token/refresh/", {
//               refresh: refreshToken,
//             })
//             .then((response) => {
//               localStorage.setItem("access_token", response.data.access);
//               localStorage.setItem("refresh_token", response.data.refresh);

//               axiosInstance.defaults.headers["Authorization"] =
//                 "JWT " + response.data.access;
//               originalRequest.headers["Authorization"] =
//                 "JWT " + response.data.access;

//               return axiosInstance(originalRequest);
//             })
//             .catch((err) => {
//               console.log(err);
//             });
//         } else {
//           console.log("Refresh token is expired", tokenParts.exp, now);
//           window.location.href = "/login/";
//         }
//       } else {
//         console.log("Refresh token not available");
//         window.location.href = "/login/";
//       }
//     }

//     return Promise.reject(error);
//   }
// );

// export default axiosInstance;

import axios from "axios";
import { jwtDecode } from "jwt-decode";

const baseURL = "https://api.olsenaeron.com/api/";
// const baseURL = "https://127.0.0.1:8000/api/";

const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("access_token")
      ? "JWT " + localStorage.getItem("access_token")
      : null,
    accept: "application/json",
  },

  withCredentials: true,
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (typeof error.response === "undefined") {
      alert(
        "A server or network error occurred. " +
          "Looks like CORS might be a problem. " +
          "Sorry about this - we will get it fixed shortly."
      );
      return Promise.reject(error);
    }

    if (
      error.response.status === 401 &&
      originalRequest.url === baseURL + "token/refresh/"
    ) {
      window.location.href = "/login/";
      return Promise.reject(error);
    }

    if (
      error.response.data.code === "token_not_valid" &&
      error.response.status === 401 &&
      error.response.statusText === "Unauthorized"
    ) {
      const refreshToken = localStorage.getItem("refresh_token");

      if (refreshToken) {
        try {
          const tokenParts = jwtDecode(refreshToken);

          const now = Math.ceil(Date.now() / 1000);
          if (tokenParts.exp > now) {
            return axiosInstance
              .post("/token/refresh/", { refresh: refreshToken })
              .then((response) => {
                localStorage.setItem("access_token", response.data.access);
                localStorage.setItem("refresh_token", response.data.refresh);

                axiosInstance.defaults.headers["Authorization"] =
                  "JWT " + response.data.access;
                originalRequest.headers["Authorization"] =
                  "JWT " + response.data.access;

                return axiosInstance(originalRequest);
              })
              .catch((err) => {
                console.log("Error refreshing token:", err);
                window.location.href = "/logout/";
              });
          } else {
            console.log("Refresh token is expired", tokenParts.exp, now);
            window.location.href = "/logout/";
          }
        } catch (e) {
          console.log("Error decoding token:", e);
          window.location.href = "/logout/";
        }
      } else {
        console.log("Refresh token not available");
        window.location.href = "/logout/";
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
